@font-face {
  font-family: 'Roobert-SemiBold';
  src: url('./assets/fonts/Roobert-SemiBold.woff');
}
@font-face {
  font-family: 'Roobert-Regular';
  src: url('./assets/fonts/Roobert-Regular.woff');
}
@font-face {
  font-family: 'Roobert-Bold';
  src: url('./assets/fonts/Roobert-Bold.woff');
}
@font-face {
  font-family: 'Roobert-Light';
  src: url('./assets/fonts/Roobert-Light.woff');
}
@font-face {
  font-family: 'Roobert-Medium';
  src: url('./assets/fonts/Roobert-Medium.woff');
}

@font-face {
  font-family: 'Pixel-Emulator';
  src: url('./assets/fonts/Pixel-Emulator.otf');
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roobert-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171425;
  width: 100%;
  /* max-width: 900px; */
  margin: auto;
  /* display: flex;
  justify-content: center; */
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  display: none;
  width: 8px;
  background-color: #0c1a3d;
}

::-webkit-scrollbar-thumb {
  background: #3f0ee6;
  border-radius: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
