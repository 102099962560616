.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 4.6%));
  grid-auto-rows: minmax(0, 5%);
  grid-gap: 2px;
  /* max-width: 100%; */
  min-height: 270px;
  padding: 20px;
  border-radius: 16px;
  border: 1px dashed #453e69;
}

.grid-item {
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item .loading-item {
  width: 56px;
  height: 8px;
  border-radius: 100px;
  background-color: #453e69;
}

.item1 {
  grid-column: span 7;
  grid-row: span 10;
  background-color: #887ae7;
}

.item2 {
  grid-column: span 5;
  grid-row: span 6;
  background-color: #383456;
}

.item3 {
  grid-column: span 2;
  grid-row: span 7;
  background-color: #443e6d;
}
.item4 {
  grid-column: span 3;
  grid-row: span 7;
  background-color: #655ba6;
}
.item5 {
  grid-column: span 3;
  grid-row: span 7;
  background-color: #2c2943;
}
.item6 {
  grid-column: span 5;
  grid-row: span 4;
  background-color: #7266bd;
}
.item7 {
  grid-column: span 4;
  grid-row: span 11;
  background-color: #655ba6;
}
.item8 {
  grid-column: span 4;
  grid-row: span 11;
  background-color: #655ba6;
}
.item9 {
  grid-column: span 7;
  grid-row: span 8;
  background-color: #443e6d;
}
.item10 {
  grid-column: span 5;
  grid-row: span 8;
  background-color: #524b83;
}
