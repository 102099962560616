.skeleton-loading {
  background-color: #838094;
  animation: loading 1s infinite;
}

.rectangle {
  width: 100px;
  height: 18px;
  /* margin: 10px; */
  border-radius: 5px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* margin: 10px; */
}

@keyframes loading {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
