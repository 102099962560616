@keyframes changeColor {
  0% {
    background-color: #272539;
  }
  25% {
    background-color: #453e69;
  }
  50% {
    background-color: #8c83c3;
  }
  75% {
    background-color: #8d86f8;
  }
  100% {
    background-color: #272539;
  }
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  background-color: #272539;
  animation: changeColor 2s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.dot:nth-child(4) {
  animation-delay: 1.5s;
}
