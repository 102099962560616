@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: flex;
  fill: #fff;
  flex-direction: row;
  outline: none;
  transition: background-color 0.15s;
  border-radius: 4px;
  overflow: hidden;
  width: 250px;
}
.container:hover,
.container:focus,
.container[data-state='open'] {
  background-color: #273251;
}

.arrow {
  fill: rgba(0, 0, 0, 0.98);
}

.dropdown {
  background-color: rgba(0, 0, 0, 0.98);
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  /* width: 250px; */
}

.dropdown[data-state='open'] {
  animation: fadeIn 0.15s ease-out;
}
.dropdown[data-state='closed'] {
  animation: fadeOut 0s ease-out;
}

.workspaceTrigger {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.15s;
}

.workspaceTrigger:hover,
.workspaceTrigger:focus {
  background-color: #273251;
}
