.ContextMenuTrigger {
  color: white;
  border-radius: 4px;
  font-size: 15px;
  user-select: none;
}

.ContextMenuContent,
.ContextMenuSubContent {
  min-width: 220px;
  background-color: #211f30;
  border-radius: 6px;
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.ContextMenuItem,
.ContextMenuCheckboxItem,
.ContextMenuRadioItem,
.ContextMenuSubTrigger {
  font-size: 14px;
  font-family: 'Roobert-Regular';
  line-height: 1;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 16px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
  cursor: default;
}
.ContextMenuSubTrigger[data-state='open'] {
  background-color: red;
  color: #fff;
}
.ContextMenuItem[data-disabled],
.ContextMenuCheckboxItem[data-disabled],
.ContextMenuRadioItem[data-disabled],
.ContextMenuSubTrigger[data-disabled] {
  color: green;
  pointer-events: 'none';
}
.ContextMenuItem[data-highlighted],
.ContextMenuCheckboxItem[data-highlighted],
.ContextMenuRadioItem[data-highlighted],
.ContextMenuSubTrigger[data-highlighted] {
  background-color: #6a53fe;
  color: #fff;
}

.ContextMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: red;
}

.ContextMenuSeparator {
  height: 1px;
  background-color: #fff;
  margin: 5px;
}

.ContextMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: #fff;
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: red;
}
